interface EnvConfig {
  GOOGLE_CLIENT_ID: string;
  API_URL: string;
  GCS_URL: string;
}

// Validate environment variables at startup
const validateEnvConfig = (): EnvConfig => {
  const requiredVars = [
    'REACT_APP_GOOGLE_CLIENT_ID',
    'REACT_APP_API_URL',
    'REACT_APP_GCS_URL'
  ];

  const missingVars = requiredVars.filter(
    varName => !process.env[varName]
  );

  if (missingVars.length > 0) {
    throw new Error(
      `Missing required environment variables: ${missingVars.join(', ')}`
    );
  }

  return {
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
    API_URL: process.env.REACT_APP_API_URL!,
    GCS_URL: process.env.REACT_APP_GCS_URL!
  };
};

export const env = validateEnvConfig();
