// src/components/ImageView.tsx

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { Image } from '../types';
import { env } from '../config/env';
import './ImageView.css';

interface ImageViewProps {
  id: string;
}

const ImageView: React.FC<ImageViewProps> = ({ id }) => {
  const [image, setImage] = useState<Image | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imageLoadError, setImageLoadError] = useState<string | null>(null);

  const gcsUrl = env.GCS_URL;

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const data = await api.getImageById(id);
        console.log('Received image data:', data);
        setImage(data);
      } catch (err) {
        setError('Failed to load image');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!image) return null;

  const getFullUrl = (url: string) => {
    if (url.startsWith('http')) return url;
    return `${gcsUrl}/${url.split('/').slice(-2).join('/')}`;
  };

  return (
    <div className="image-view">
      <h1>{image.title}</h1>
      <div className="image-container">
        {imageLoadError ? (
          <p>Error loading image: {imageLoadError}</p>
        ) : (
          <img 
            src={getFullUrl(image.fullUrl || image.url)} 
            alt={image.title} 
            onError={(e) => {
              console.error('Image load error:', e);
              setImageLoadError('Failed to load image');
            }}
          />
        )}
      </div>
      <div className="metadata">
        <h2>Metadata</h2>
        <p><strong>Description:</strong> {image.description}</p>
        <p><strong>Style:</strong> {image.style}</p>
        <p><strong>Dominant Colors:</strong> {Array.isArray(image.dominantColors) ? image.dominantColors.join(', ') : 'N/A'}</p>
        {image.objects && <p><strong>Objects Detected:</strong> {image.objects.join(', ')}</p>}
        <p><strong>Original File Name:</strong> {image.title}</p>
        <p><strong>Unique Filename:</strong> {image.id}</p>
        <h4>Image URLs:</h4>
        <ul>
          <li><strong>Thumbnail:</strong> <a href={getFullUrl(image.thumbnailUrl)} target="_blank" rel="noopener noreferrer">View</a></li>
          <li><strong>Full Size:</strong> <a href={getFullUrl(image.fullUrl || image.url)} target="_blank" rel="noopener noreferrer">View</a></li>
        </ul>
        {image.mood && <p><strong>Mood:</strong> {image.mood}</p>}
        {image.composition && <p><strong>Composition:</strong> {image.composition}</p>}
        {image.technique && <p><strong>Technique:</strong> {image.technique}</p>}
      </div>
    </div>
  );
};

export default ImageView;