// components/GraphNode.tsx
import { useCallback } from 'react';
import type { GraphNode, ImageGraphNode, UserNode, AttributeNode } from '../types';
import { useGraphSettings } from '../contexts/GraphSettingsContext';
import { COLORS } from '../constants/colors';

interface GraphNodeRendererProps {
  nodeImages: { [key: string]: { img: HTMLImageElement; aspectRatio: number } };
  getCategoryColor: (type: string) => string;
  hoveredNode: string | null;
  hoveredNodeLinks: Set<string>;
  zoomLevel: number;
}

export const useGraphNodeRenderer = ({ 
  nodeImages, 
  getCategoryColor, 
  hoveredNode,
  hoveredNodeLinks,
  zoomLevel 
}: GraphNodeRendererProps) => {
  const { nodeSizes } = useGraphSettings();

  const renderNode = useCallback((
    node: GraphNode, 
    ctx: CanvasRenderingContext2D, 
    globalScale: number
  ) => {
    const isHovered = hoveredNode === node.id;
    const isLinkedToHovered = hoveredNodeLinks.has(node.id);
    const scaleFactor = isHovered ? 1.2 : isLinkedToHovered ? 1.1 : 1;
    
    const label = node.label || '';
    const fontSize = 12/globalScale;
    ctx.font = `${fontSize}px Arial`;
    
    let nodeHeight = 0;
    
    switch (node.type) {
      case 'image': {
        const imageNode = node as ImageGraphNode;
        if (nodeImages[imageNode.id]) {
          const zoomAdjustedSize = nodeSizes.IMAGE.BASE * Math.sqrt(zoomLevel);
          const baseSize = (zoomAdjustedSize/globalScale) * scaleFactor;
          const { img, aspectRatio } = nodeImages[imageNode.id];
          const width = baseSize * Math.sqrt(aspectRatio);
          nodeHeight = baseSize / Math.sqrt(aspectRatio);
          
          ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
          ctx.shadowBlur = (isHovered ? 15 : 10)/globalScale;
          ctx.shadowOffsetX = 5/globalScale;
          ctx.shadowOffsetY = 5/globalScale;
          
          ctx.drawImage(
            img,
            imageNode.x! - width/2,
            imageNode.y! - nodeHeight/2,
            width,
            nodeHeight
          );
          
          ctx.shadowColor = 'transparent';
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
        }
        break;
      }
      case 'user': {
        const userNode = node as UserNode;
        ctx.fillStyle = userNode.isCurrentUser ? '#ff6b6b' : '#4dabf7';
        ctx.beginPath();
        ctx.arc(node.x!, node.y!, nodeSizes.USER.RADIUS/globalScale, 0, 2 * Math.PI);
        ctx.fill();
        break;
      }
      case 'attribute': {
        const attrNode = node as AttributeNode;
        const radius = (nodeSizes.ATTRIBUTE.RADIUS/globalScale) * scaleFactor;
        
        // Draw glow for linked attributes
        if (isLinkedToHovered) {
          ctx.beginPath();
          ctx.arc(node.x!, node.y!, radius + 3/globalScale, 0, 2 * Math.PI);
          ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
          ctx.fill();
        }
        
        ctx.fillStyle = getCategoryColor(attrNode.attributeType);
        ctx.beginPath();
        ctx.arc(node.x!, node.y!, radius, 0, 2 * Math.PI);
        ctx.fill();
        
        if (isHovered || isLinkedToHovered) {
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 2/globalScale;
          ctx.stroke();
        }
        break;
      }
    }
    
    if (node.type !== 'image' || hoveredNode === node.id) {
      const labelOffset = node.type === 'image' 
        ? nodeHeight/2 + fontSize/2 
        : nodeSizes.USER.RADIUS/globalScale + fontSize/2;
      
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      ctx.fillStyle = COLORS.TEXT_DEFAULT;
      
      ctx.shadowColor = COLORS.TEXT_SHADOW;
      ctx.shadowBlur = 4/globalScale;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      
      ctx.fillText(label, node.x!, node.y! + labelOffset);
      
      ctx.shadowColor = 'transparent';
      ctx.shadowBlur = 0;
    }
  }, [nodeImages, nodeSizes, hoveredNode, hoveredNodeLinks, zoomLevel, getCategoryColor]);

  return { renderNode };
};

