// src/reportWebVitals.ts

import { Metric, onCLS, onFID, onFCP, onLCP, onTTFB, onINP } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void): Promise<void> => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    return Promise.all([
      onCLS(onPerfEntry),
      onFID(onPerfEntry),
      onFCP(onPerfEntry),
      onLCP(onPerfEntry),
      onTTFB(onPerfEntry),
      onINP(onPerfEntry)
    ]).then(() => {
      // Keeping this log for now, but consider removing it in production
      console.log('All web-vitals functions completed');
    });
  }
  return Promise.resolve();
};

export default reportWebVitals;