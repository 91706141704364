// src/pages/Browse.tsx

import React, { useState, useEffect, useCallback } from 'react';
import api from '../services/api';
import logger from '../utils/logger';
import GraphVisualization from '../components/GraphVisualization';
import { GraphData } from '../types'
import { useAuth } from '../contexts/AuthContext';

interface BrowseProps {
  uploadOccurred: boolean;
  setUploadOccurred: React.Dispatch<React.SetStateAction<boolean>>;
}

const Browse: React.FC<BrowseProps> = ({ uploadOccurred, setUploadOccurred }) => {
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token, user } = useAuth();

  // Add this logging
  useEffect(() => {
    console.log('Auth state:', { 
      hasToken: !!token, 
      user,
      userId: user?.sub  // Change from id to sub
    });
  }, [token, user]);

  const fetchGraphData = useCallback(async () => {
    if (token && user?.sub) {
      try {
        setLoading(true);
        setError(null);
        console.log('Fetching graph data...');
        const data = await api.getGraphData(token);
        console.log('Received graph data:', data);
        setGraphData(data);
      } catch (error) {
        console.error('Error fetching graph data:', error);
        logger.error('Error fetching graph data:', error);
        setError('Failed to fetch graph data. Please try again later.');
      } finally {
        setLoading(false);
      }
    } else {
      console.log('Missing token or user.sub:', { hasToken: !!token, userSub: user?.sub });
    }
  }, [token, user?.sub]);  // Change dependency from id to sub

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  useEffect(() => {
    if (uploadOccurred) {
      fetchGraphData();
      setUploadOccurred(false);
    }
  }, [uploadOccurred, fetchGraphData, setUploadOccurred]);

  logger.info('Browse component initialized');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!user?.sub) {  // Change from id to sub
    return <div>Loading user data...</div>;
  }

  return (
    <div className="browse-page" data-testid="browse-page">
      <h1>Browse Images</h1>
      {graphData ? (
        <GraphVisualization 
          data={graphData} 
          userId={user.sub}  // Change from id to sub
        />
      ) : (
        <p>No graph data available</p>
      )}
    </div>
  );
};

export default Browse;
