// src/components/NavigationBar.tsx
import React from 'react';
import { Navbar, Nav } from 'rsuite';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface NavigationBarProps {
  onUploadClick: () => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ onUploadClick }) => {
  const { user, logout, isAuthenticated, isWhitelisted } = useAuth();

  return (
    <Navbar>
      <Navbar.Brand as={Link} to="/">starz</Navbar.Brand>
      <Nav>
        {isAuthenticated && isWhitelisted && (
          <>
            <Nav.Item as={Link} to="/browse">Browse</Nav.Item>
            <Nav.Item onClick={onUploadClick} data-testid="upload-button">Upload</Nav.Item>
          </>
        )}
      </Nav>
      <Nav pullRight>
        {isAuthenticated ? (
          <>
            <Nav.Item data-testid="user-name">{user?.name || user?.email}</Nav.Item>
            <Nav.Item onClick={logout}>Logout</Nav.Item>
          </>
        ) : (
          <Nav.Item as={Link} to="/login">Login</Nav.Item>
        )}
      </Nav>
    </Navbar>
  );
};

export default NavigationBar;
