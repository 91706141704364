import React from 'react';
import { Uploader, Message } from 'rsuite';
import { useAuth } from '../contexts/AuthContext';
import { toaster } from 'rsuite';
import { env } from '../config/env';
import type { FileType } from 'rsuite/Uploader';

interface UploadDrawerProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

const UploadDrawer: React.FC<UploadDrawerProps> = ({ open, onClose, onUploadSuccess }) => {
  const { token } = useAuth();

  console.log('Token in UploadDrawer:', token);

  const handleUploadSuccess = (response: any, file: FileType) => {
    console.log('Upload successful:', response);
    toaster.push(<Message type="success">Upload successful!</Message>);
    onUploadSuccess();
    onClose();
  };

  const handleUploadError = (reason: any, file: FileType) => {
    console.error('Upload error:', reason);
    toaster.push(<Message type="error">Upload failed. Please try again.</Message>);
  };

  const shouldUpload = (file: FileType): boolean => {
    const MAX_SIZE = 10 * 1024 * 1024; // 10MB
    console.log('Uploading file:', {
      name: file.name,
      size: file.blobFile?.size,
      type: file.blobFile?.type
    });
    if (file.blobFile && file.blobFile.size > MAX_SIZE) {
      toaster.push(<Message type="error">File size exceeds 10MB limit.</Message>);
      return false;
    }
    return true;
  };

  return (
    <div>
      <h4>Upload Image</h4>
      <Uploader
        action={`${env.API_URL}/upload`}
        accept="image/*"
        onSuccess={handleUploadSuccess}
        onError={handleUploadError}
        shouldUpload={shouldUpload}
        draggable
        multiple={false}
        autoUpload={true}
        name="image"
        headers={{
          Authorization: `Bearer ${token}`
        }}
        onUpload={(file) => {
          console.log('Uploading file:', file);
          console.log('Headers:', {
            Authorization: `Bearer ${token}`
          });
        }}
      >
        <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>Click or Drag files to this area to upload</span>
        </div>
      </Uploader>
    </div>
  );
};

export default UploadDrawer;
