export const COLORS = {
    // Link colors
    LINK_DEFAULT: '#666666',
    LINK_HIGHLIGHT: '#ffffff',

    // Node colors
    NODE_USER_CURRENT: '#ff6b6b',
    NODE_USER_OTHER: '#4dabf7',

    // Text colors
    TEXT_DEFAULT: '#ffffff',
    TEXT_SHADOW: 'rgba(0, 0, 0, 0.6)',

    // Background colors
    BG_DARK: '#1f2937',

    // Particle colors
    PARTICLE_DEFAULT: '#666666',
    PARTICLE_HIGHLIGHT: '#ffffff',
} as const;