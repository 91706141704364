// hooks/useForceGraphConfig.ts
import { useCallback } from 'react';
import type { GraphData, GraphNode, GraphLink } from '../types';
import type { ForceGraphMethods } from 'react-force-graph-2d';
import * as d3 from 'd3';

export const useForceGraphConfig = (
  graphData: GraphData,
  graphPhysics: any,
  zoomLevel: number = 1
) => {
  const configureForces = useCallback((fgRef: ForceGraphMethods) => {
    // Add node lookup helper inside callback
    const nodeMap = new Map(graphData.nodes.map(node => [node.id, node]));
    const getNode = (id: string | GraphNode) => 
      typeof id === 'object' ? id : nodeMap.get(id);

    // Calculate graph scale factors
    const nodeCount = graphData.nodes.length;
    const graphScale = Math.log(nodeCount) / Math.log(10);
    const baseStrength = Math.min(0.3, 1 / Math.log(nodeCount));

    // Build attribute connection map
    const attributeConnectionMap = new Map<string, Set<string>>();
    graphData.links.forEach(link => {
      const source = getNode(link.source);
      const target = getNode(link.target);
      if (!source || !target) return;
      
      if (source.type === 'attribute' && target.type === 'image') {
        const connections = attributeConnectionMap.get(source.id) || new Set();
        connections.add(target.id);
        attributeConnectionMap.set(source.id, connections);
      } else if (target.type === 'attribute' && source.type === 'image') {
        const connections = attributeConnectionMap.get(target.id) || new Set();
        connections.add(source.id);
        attributeConnectionMap.set(target.id, connections);
      }
    });

    // Enhanced hierarchical link force
    const linkForce = d3.forceLink<GraphNode, GraphLink>(graphData.links)
      .id(d => d.id)
      .distance(link => {
        const source = getNode(link.source);
        const target = getNode(link.target);
        
        if (!source || !target) return graphPhysics.dagLevelDistance;

        if (source.type === 'root' || target.type === 'root') {
          return graphPhysics.userClusterSpacing * graphScale;
        }
        if (source.type === 'image' && target.type === 'image') {
          return graphPhysics.dagLevelDistance * 3 * graphScale;
        }
        if ((source.type === 'image' && target.type === 'attribute') ||
            (source.type === 'attribute' && target.type === 'image')) {
          const attributeNode = source.type === 'attribute' ? source : target;
          const connections = attributeConnectionMap.get(attributeNode.id);
          return graphPhysics.dagLevelDistance * 
            (connections?.size === 1 ? 2 : 3) * 
            graphScale;
        }
        return graphPhysics.dagLevelDistance * 1.5 * graphScale;
      })
      .strength(link => {
        const source = getNode(link.source);
        const target = getNode(link.target);
        
        if (!source || !target) return baseStrength;

        // Adjust strength based on node types and connections
        if ((source.type === 'image' && target.type === 'attribute') ||
            (source.type === 'attribute' && target.type === 'image')) {
          const attributeNode = source.type === 'attribute' ? source : target;
          const connections = attributeConnectionMap.get(attributeNode.id);
          return connections?.size === 1 ? 0.5 : 0.3;
        }
        return baseStrength;
      });

    // Layer-based radial force
    const radialForce = d3.forceRadial<GraphNode>(
      node => {
        if (node.type === 'attribute') {
          const connections = attributeConnectionMap.get(node.id);
          return graphPhysics.userClusterSpacing * (connections?.size === 1 ? 2 : 2.5) * graphScale;
        }
        switch(node.type) {
          case 'root': return 0;
          case 'user': return graphPhysics.userClusterSpacing * graphScale;
          case 'image': return graphPhysics.userClusterSpacing * 1.8 * graphScale;
          default: return 0;
        }
      },
      window.innerWidth / 2,
      window.innerHeight / 2
    ).strength(node => {
      if (node.type === 'attribute') {
        const connections = attributeConnectionMap.get(node.id);
        return connections?.size === 1 ? 0.4 : 0.3;
      }
      return 0.3;
    });

    // Stronger charge force for better spacing
    const chargeForce = d3.forceManyBody<GraphNode>()
      .strength(d => {
        switch(d.type) {
          case 'root': return -graphPhysics.userClusterSpacing * 2;
          case 'user': return -graphPhysics.dagLevelDistance * 4;
          case 'image': return -graphPhysics.dagLevelDistance * 3;
          case 'attribute': return -graphPhysics.dagLevelDistance * 2;
          default: return 0;
        }
      });

    // Increased collision force
    const collisionForce = d3.forceCollide<GraphNode>()
      .radius(d => {
        switch(d.type) {
          case 'image': return graphPhysics.dagLevelDistance * 1.5;
          case 'attribute': return graphPhysics.dagLevelDistance;
          default: return graphPhysics.dagLevelDistance * 0.75;
        }
      })
      .strength(0.7);

    // Apply forces
    fgRef.d3Force('radial', radialForce as any);
    fgRef.d3Force('link', linkForce as any);
    fgRef.d3Force('charge', chargeForce as any);
    fgRef.d3Force('collision', collisionForce as any);
  }, [graphData, graphPhysics]);

  return { configureForces };
};
