import { useMemo } from 'react';
import type { GraphData, GraphLink, RootNode } from '../types';

const calculateInitialPositions = (nodes: any[], centerX: number, centerY: number) => {
  // Group nodes by type
  const userNodes = nodes.filter(n => n.type === 'user');
  const imageNodes = nodes.filter(n => n.type === 'image');
  
  // Fix root at center
  const rootNode = nodes.find(n => n.type === 'root');
  if (rootNode) {
    rootNode.x = centerX;
    rootNode.y = centerY;
    rootNode.fx = centerX;
    rootNode.fy = centerY;
  }

  // Position users in a circle around root
  const userRadius = Math.min(window.innerWidth, window.innerHeight) * 0.3;
  userNodes.forEach((node, i) => {
    const angle = (i * 2 * Math.PI) / userNodes.length;
    // Start at 0 degrees (right side) and go clockwise
    node.x = centerX + Math.cos(angle) * userRadius;
    node.y = centerY + Math.sin(angle) * userRadius;
    // Fix user positions more strongly
    node.fx = node.x;
    node.fy = node.y;
  });

  // Position images in the same direction as their parent user
  imageNodes.forEach(node => {
    const connectedUser = userNodes.find(user => 
      nodes.some(link => 
        (link.source === user.id && link.target === node.id) ||
        (link.source === node.id && link.target === user.id)
      )
    );
    
    if (connectedUser) {
      // Calculate angle from root to user
      const userAngle = Math.atan2(
        connectedUser.y - centerY,
        connectedUser.x - centerX
      );
      
      // Position image further out in same direction
      const imageRadius = userRadius * 1.5;
      node.x = centerX + Math.cos(userAngle) * imageRadius;
      node.y = centerY + Math.sin(userAngle) * imageRadius;
      // Semi-fix image position
      node.fx = node.x;
      node.fy = node.y;
    }
  });
};

export const useHierarchicalGraph = (data: GraphData) => {
  return useMemo(() => {
    // Return empty graph if data is undefined or missing nodes/links
    if (!data?.nodes || !data?.links) {
      return {
        nodes: [],
        links: []
      };
    }

    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    const rootNode: RootNode = {
      id: 'root',
      label: 'Root',
      type: 'root',
      fx: centerX,
      fy: centerY,
      x: centerX,
      y: centerY,
      vx: 0,
      vy: 0
    };

    const nodes = [rootNode, ...data.nodes.filter(n => n.type !== 'root')];
    
    // Set initial positions before creating the node map
    calculateInitialPositions(nodes, centerX, centerY);
    
    const nodeMap = new Map(nodes.map(node => [node.id, node]));

    // Rest of the existing code remains the same
    const userNodes = nodes.filter(node => node.type === 'user');
    const rootLinks = userNodes.map(user => ({
      id: `root-${user.id}`,
      source: nodeMap.get('root')!,
      target: nodeMap.get(user.id)!,
      type: 'hierarchy',
      strength: 2
    } as GraphLink));

    const formattedLinks = data.links
      .map(link => {
        const sourceNode = nodeMap.get(typeof link.source === 'string' ? link.source : link.source.id);
        const targetNode = nodeMap.get(typeof link.target === 'string' ? link.target : link.target.id);
        
        if (!sourceNode || !targetNode) {
          console.warn('Missing node reference:', { link, sourceNode, targetNode });
          return null;
        }

        return {
          id: link.id || `${sourceNode.id}-${targetNode.id}`,
          source: sourceNode,
          target: targetNode,
          type: link.type
        } as GraphLink;
      })
      .filter((link): link is GraphLink => link !== null);

    return {
      nodes,
      links: [...formattedLinks, ...rootLinks]
    };
  }, [data]);
};
