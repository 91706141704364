import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import type {
  GraphVisualizationProps, 
  GraphNode, 
  ImageGraphNode,
  ForceGraphMethods,
  GraphLink
} from '../types';
import NodeDetailModal from './NodeDetailModal';
import { useForceGraphConfig } from '../hooks/useForceGraphConfig';
import { useGraphNodeRenderer } from './GraphNode';
import { GraphSettingsProvider, useGraphSettings } from '../contexts/GraphSettingsContext';
import { useHierarchicalGraph } from '../hooks/useHierarchicalGraph';
import { getLinkId } from '../utils/graph';
import { COLORS } from '../constants/colors';

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 1;

const GraphVisualization: React.FC<GraphVisualizationProps> = ({ data, userId }) => {
  const [nodeImages, setNodeImages] = useState<{ [key: string]: { img: HTMLImageElement; aspectRatio: number } }>({});
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const fgRef = useRef<ForceGraphMethods>();
  const { nodeSizes, graphPhysics } = useGraphSettings();
  const [highlightLinkIds, setHighlightLinkIds] = useState<Set<string>>(new Set<string>());
  const [hoveredNodeLinks, setHoveredNodeLinks] = useState<Set<string>>(new Set<string>());
  const [seenLinks] = useState(() => new Set<string>());

  // Load images for image nodes
  useEffect(() => {
    if (!data?.nodes) return;
    
    const imageNodes = data.nodes.filter(node => node.type === 'image') as ImageGraphNode[];
    
    imageNodes.forEach(node => {
      const img = new Image();
      img.src = node.thumbnailUrl;
      img.onload = () => {
        setNodeImages(prev => ({
          ...prev,
          [node.id]: {
            img,
            aspectRatio: img.width / img.height
          }
        }));
      };
    });
  }, [data?.nodes]);

  // Existing state declarations
  const hierarchicalData = useHierarchicalGraph(data);
  const { configureForces } = useForceGraphConfig(hierarchicalData, graphPhysics, zoomLevel);

  // Move getCategoryColor before its usage
  const getCategoryColor = useCallback((type: string) => {
    const colors: { [key: string]: string } = {
      style: '#ffd93d',
      mood: '#4cd137',
      technique: '#00a8ff',
      color: '#ff793f',
      object: '#a55eea'
    };
    return colors[type] || '#8c8c8c';
  }, []);

  // Add this after the nodeCanvasObject function
  const nodePointerAreaPaint = useCallback((node: GraphNode, color: string, ctx: CanvasRenderingContext2D, globalScale: number) => {
    ctx.fillStyle = color;
    
    switch (node.type) {
      case 'image':
        if (nodeImages[node.id]) {
          const zoomAdjustedSize = nodeSizes.IMAGE.BASE * Math.sqrt(zoomLevel);
          const baseSize = zoomAdjustedSize/globalScale;
          const { aspectRatio } = nodeImages[node.id];
          const width = baseSize * Math.sqrt(aspectRatio);
          const height = baseSize / Math.sqrt(aspectRatio);
          
          ctx.fillRect(
            node.x! - width/2,
            node.y! - height/2,
            width,
            height
          );
        }
        break;
      
      case 'user':
        ctx.beginPath();
        ctx.arc(node.x!, node.y!, nodeSizes.USER.RADIUS/globalScale, 0, 2 * Math.PI);
        ctx.fill();
        break;
      
      case 'attribute':
        ctx.beginPath();
        ctx.arc(node.x!, node.y!, nodeSizes.ATTRIBUTE.RADIUS/globalScale, 0, 2 * Math.PI);
        ctx.fill();
        break;
    }
  }, [nodeImages, nodeSizes, zoomLevel]);

  // Add this after the nodeCanvasObject function
  const { renderNode } = useGraphNodeRenderer({ 
    nodeImages, 
    getCategoryColor, 
    hoveredNode,
    hoveredNodeLinks,
    zoomLevel 
  });

  // Add initialization state tracking
  const [isInitialized, setIsInitialized] = useState(false);

  // Move centerAndFitView here
  const centerAndFitView = useCallback(() => {
    if (!fgRef.current) return;
    
    const currentUser = data.nodes.find(n => n.id === userId);
    if (!currentUser) return;
    
    // Add safety check for coordinates
    if (typeof currentUser.x !== 'number' || typeof currentUser.y !== 'number') {
      console.warn('Missing coordinates for user node:', currentUser.id);
      return;
    }
    
    // Calculate zoom level
    const maxRadius = data.nodes.reduce((max, node) => {
      if (node.type === 'image') {
        return Math.max(max, graphPhysics.dagLevelDistance);
      }
      if (node.type === 'attribute') {
        return Math.max(max, graphPhysics.dagLevelDistance * 2);
      }
      if (node.type === 'user' && node.id !== userId) {
        return Math.max(max, graphPhysics.userClusterSpacing);
      }
      return max;
    }, 0);

    const padding = 100;
    const viewRadius = maxRadius + padding;
    const zoom = Math.min(
      window.innerWidth / (viewRadius * 2),
      window.innerHeight / (viewRadius * 2)
    );

    // Center and zoom immediately without animation
    fgRef.current.centerAt(currentUser.x, currentUser.y, 0);
    fgRef.current.zoom(zoom, 0);
  }, [data.nodes, userId, graphPhysics]);

  // Then keep the existing useEffects in their current order
  useEffect(() => {
    if (!fgRef.current) return;
    
    // Clear any existing simulation
    fgRef.current.d3Force('link', null);
    fgRef.current.d3Force('charge', null);
    fgRef.current.d3Force('collision', null);
    fgRef.current.d3Force('center', null);
    
    // Configure forces with reduced initial alpha
    const fg = fgRef.current;
    configureForces(fg);
    
    // Set initial simulation parameters
    const simulation = fg.d3Force('simulation');
    if (simulation) {
      simulation
        .alpha(0.3)
        .alphaDecay(graphPhysics.d3AlphaDecay)
        .velocityDecay(graphPhysics.d3VelocityDecay)
        .alphaMin(0.001);
    }
    
    // Start simulation with reduced energy
    fg.d3ReheatSimulation();
    setIsInitialized(true);
    
    // Center immediately without animation
    centerAndFitView();
  }, [
    configureForces, 
    centerAndFitView, 
    userId, 
    graphPhysics.d3AlphaDecay,
    graphPhysics.d3VelocityDecay
  ]);

  // Update forces when data changes
  useEffect(() => {
    if (!fgRef.current || !isInitialized) return;
    
    configureForces(fgRef.current);
    fgRef.current.d3ReheatSimulation();
  }, [configureForces, isInitialized]);

  useEffect(() => {
    if (!fgRef.current) return;
    
    const fg = fgRef.current;
    
    // Get the simulation by requesting the 'simulation' force
    const simulation = fg.d3Force('simulation');
    if (simulation) {
      // Update simulation parameters
      simulation
        .alpha(1)
        .alphaDecay(graphPhysics.d3AlphaDecay)
        .velocityDecay(graphPhysics.d3VelocityDecay);
    }
    
    // Reheat the simulation when physics change
    fg.d3ReheatSimulation();
  }, [graphPhysics]);

  // Add zoom constraint handler
  const handleZoom = useCallback(({ k }: { k: number }) => {
    if (k < MIN_ZOOM || k > MAX_ZOOM) {
      if (fgRef.current) {
        const constrainedZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, k));
        fgRef.current.zoom(constrainedZoom, 0);
        setZoomLevel(constrainedZoom);
      }
      return;
    }
    setZoomLevel(k);
  }, []);

  const updateHighlightState = useCallback((links: GraphLink[], nodeLinks: Set<string>, link: GraphLink | null = null, node: string | null = null) => {
    const linkIds = new Set(links.map(getLinkId));
    
    console.log('State Update:', {
      action: node ? 'hover' : 'unhover',
      linkCount: linkIds.size,
      nodeCount: nodeLinks.size,
      firstLink: links[0] ? getLinkId(links[0]) : null
    });
    
    seenLinks.clear();
    setHighlightLinkIds(linkIds);
    setHoveredNodeLinks(nodeLinks);
    setHoveredNode(node);
  }, [seenLinks]);

  const handleNodeHover = useCallback((node: GraphNode | null) => {
    if (!node) {
      updateHighlightState([], new Set(), null, null);
      return;
    }
    
    const relevantLinks = data.links.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      return sourceId === node.id || targetId === node.id;
    });
    
    const linkedNodeIds = new Set(
      relevantLinks.flatMap(link => {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;
        return [sourceId, targetId];
      })
    );
    
    updateHighlightState(relevantLinks, linkedNodeIds, null, node.id);
  }, [data.links, updateHighlightState]);

  const handleLinkHover = useCallback((link: GraphLink | null) => {
    if (!link) {
      updateHighlightState([], new Set(), null, null);
      return;
    }
    
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
    
    updateHighlightState(
      [link],
      new Set([sourceId, targetId]),
      link,
      null
    );
  }, [updateHighlightState]);

  const getLinkColor = useCallback((link: GraphLink) => {
    return COLORS.LINK_DEFAULT;
  }, []);

  const getLinkWidth = useCallback((link: GraphLink) => 
    highlightLinkIds.has(getLinkId(link)) ? 3 : 2,
    [highlightLinkIds]
  );

  const getLinkParticles = useCallback(() => {
    // Always show particles
    return 4;
  }, []);

  const getLinkParticleWidth = useCallback((link: GraphLink) => {
    const linkId = getLinkId(link);
    const isHighlighted = highlightLinkIds.has(linkId);
    return isHighlighted ? 4 : 2;
  }, [highlightLinkIds]);

  const getLinkParticleColor = useCallback((link: GraphLink) => {
    const linkId = getLinkId(link);
    const isHighlighted = highlightLinkIds.has(linkId);
    return isHighlighted ? COLORS.PARTICLE_HIGHLIGHT : COLORS.PARTICLE_DEFAULT;
  }, [highlightLinkIds]);

  useEffect(() => {
    if (isInitialized && fgRef.current) {
      // Force a re-render of the particles after initialization
      fgRef.current.d3ReheatSimulation();
    }
  }, [isInitialized]);

  return (
    <>
      <ForceGraph2D
        ref={fgRef}
        graphData={hierarchicalData}
        nodeCanvasObject={renderNode}
        nodePointerAreaPaint={nodePointerAreaPaint}
        nodeCanvasObjectMode={() => 'replace'}
        onNodeClick={node => setSelectedNode(node)}
        onNodeHover={handleNodeHover}
        onLinkHover={handleLinkHover}
        onZoom={handleZoom}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        linkVisibility={true}
        linkColor={getLinkColor}
        linkHoverPrecision={0}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkDirectionalParticleSpeed={0.01}
        linkDirectionalParticleColor={getLinkParticleColor}
        backgroundColor={COLORS.BG_DARK}
      />
      <NodeDetailModal
        node={selectedNode}
        open={!!selectedNode}
        onClose={() => setSelectedNode(null)}
      />
    </>
  );
};

const GraphVisualizationWithControls: React.FC<GraphVisualizationProps> = (props) => {
  return (
    <GraphSettingsProvider>
      <GraphVisualization {...props} />
    </GraphSettingsProvider>
  );
};

export default GraphVisualizationWithControls;
