import React, { createContext, useContext, useState } from 'react';
import { GRAPH_NODE_SIZES as defaultSizes } from '../constants';

export interface GraphSettings {
  nodeSizes: typeof defaultSizes;
  graphPhysics: {
    // Layer Configuration
    dagLevelDistance: number;  // Distance between layers
    layerStrength: number;     // How strongly nodes stick to their layer
    userClusterSpacing: number; // Minimum distance between user clusters

    // Graph Physics
    d3AlphaDecay: number;      // How quickly the simulation cools down
    d3VelocityDecay: number;   // How quickly nodes lose momentum
    
    // Simulation Settings
    warmupTicks: number;       // Initial simulation iterations
    cooldownTicks: number;     // Post-interaction iterations
  };
  updateNodeSizes: (newSizes: typeof defaultSizes) => void;
  updateGraphPhysics: (newPhysics: GraphSettings['graphPhysics']) => void;
}

const GraphSettingsContext = createContext<GraphSettings | undefined>(undefined);

export const GraphSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [nodeSizes, setNodeSizes] = useState(defaultSizes);
  const [graphPhysics, setGraphPhysics] = useState({
    // Layer Configuration
    dagLevelDistance: 200,    // Increased for better layer separation
    layerStrength: 0.4,      // Stronger layer adherence
    userClusterSpacing: 1000, // More space between user clusters

    // Graph Physics
    d3AlphaDecay: 0.015,     // Slightly faster cooling
    d3VelocityDecay: 0.25,   // Less damping for smoother movement

    // Simulation Settings
    warmupTicks: 100,        // More initial ticks for better settling
    cooldownTicks: 50
  });

  const updateNodeSizes = (newSizes: typeof defaultSizes) => {
    setNodeSizes(newSizes);
  };

  const updateGraphPhysics = (newPhysics: GraphSettings['graphPhysics']) => {
    setGraphPhysics(newPhysics);
  };

  return (
    <GraphSettingsContext.Provider value={{ 
      nodeSizes, 
      graphPhysics, 
      updateNodeSizes,
      updateGraphPhysics 
    }}>
      {children}
    </GraphSettingsContext.Provider>
  );
};

export const useGraphSettings = () => {
  const context = useContext(GraphSettingsContext);
  if (!context) {
    throw new Error('useGraphSettings must be used within a GraphSettingsProvider');
  }
  return context;
};

