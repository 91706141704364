import { GraphLink } from '../types';

export const getLinkId = (link: GraphLink) => {
  const source = typeof link.source === 'object' ? link.source : link.source;
  const target = typeof link.target === 'object' ? link.target : link.target;
  
  const sourceId = typeof source === 'string' ? source : source.id;
  const targetId = typeof target === 'string' ? target : target.id;
  
  return `${sourceId}-${targetId}`;
};
