import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { Image, User, AuthResponse } from '../types/index';
import logger from '../utils/logger';
import { env } from '../config/env';

// Environment configuration
const API_URL = env.API_URL;

// Log environment configuration
logger.info('API Service Configuration:', {
  environment: process.env.NODE_ENV,
  apiUrl: API_URL
});

// Base configuration for axios
const axiosConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000, // Increase timeout to 120 seconds for complex graph operations
  validateStatus: (status: number) => status < 500,
  retryConfig: {
    retries: 3,
    retryDelay: 1000,
    retryCondition: (error: AxiosError) => {
      return error.code === 'ECONNABORTED' || error.code === 'ETIMEDOUT';
    }
  }
};

// Create axios instance with base configuration
const axiosInstance = axios.create(axiosConfig);

// Initialize auth header if token exists
const storedToken = localStorage.getItem('accessToken');
if (storedToken) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
}

// Extend AxiosRequestConfig to include our custom properties
interface ExtendedRequestConfig extends InternalAxiosRequestConfig {
  _startTime?: number;
}

// Add request interceptor to track timing
axiosInstance.interceptors.request.use(
  (config: ExtendedRequestConfig) => {
    config._startTime = Date.now();
    logger.info('API request started:', {
      url: config.url,
      method: config.method,
      timeout: config.timeout
    });
    return config;
  },
  (error) => {
    logger.error('API request configuration failed:', error);
    return Promise.reject(error);
  }
);

// Add token refresh interceptor
let isRefreshing = false;
let failedQueue: { resolve: (token: string) => void; reject: (error: any) => void; }[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token!);
    }
  });
  
  failedQueue = [];
};

const refreshAuthToken = async (refreshToken: string): Promise<string> => {
  try {
    const response = await axiosInstance.post('/auth/refresh-token', { refresh_token: refreshToken });
    return response.data.access_token;
  } catch (error) {
    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise<string>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const access_token = await refreshAuthToken(refreshToken);
        localStorage.setItem('accessToken', access_token);
        
        // Update authorization header
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        
        processQueue(null, access_token);
        return axiosInstance(originalRequest);
      } catch (error) {
        processQueue(error, null);
        // Clear auth state on refresh token failure
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    logger.error('API request failed:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      error: error.message,
      duration: (error.config as ExtendedRequestConfig)?._startTime 
        ? Date.now() - (error.config as ExtendedRequestConfig)._startTime! 
        : undefined,
      timeout: error.config?.timeout,
      code: error.code,
      isTimeout: error.code === 'ECONNABORTED',
      responseHeaders: error.response?.headers,
      requestHeaders: error.config?.headers
    });
    throw error;
  }
);

// Add retry interceptor
axiosInstance.interceptors.response.use(undefined, async (err) => {
  const config = err.config;
  if (!config || !config.retryConfig || !config.retryConfig.retries) {
    return Promise.reject(err);
  }

  config.retryCount = config.retryCount || 0;
  if (config.retryCount >= config.retryConfig.retries) {
    return Promise.reject(err);
  }

  config.retryCount += 1;
  const delayMs = config.retryConfig.retryDelay * config.retryCount;
  await new Promise(resolve => setTimeout(resolve, delayMs));
  return axiosInstance(config);
});

export interface PaginatedResponse<T> {
  images: T[];
  totalCount: number;
  hasMore: boolean;
  nextCursor?: string;
}

const api = {
  setAuthToken: (token: string | null) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  },

  uploadImage: async (formData: FormData, token: string, onUploadProgress: (progressEvent: any) => void) => {
    const response = await axiosInstance.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      onUploadProgress,
    });
    return response.data;
  },

  getImages: async (limit: number = 20, cursor?: string): Promise<PaginatedResponse<Image>> => {
    const response = await axiosInstance.get('/images', {
      params: { limit, cursor }
    });
    return response.data;
  },

  getImageById: async (id: string): Promise<Image> => {
    const response = await axiosInstance.get(`/images/${id}`);
    return response.data;
  },

  getUserInfo: async (accessToken: string): Promise<User> => {
    const response = await axiosInstance.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  auth: {
    exchangeCodeForTokens: async (code: string): Promise<AuthResponse> => {
      logger.info('Exchanging auth code for tokens');
      try {
        const response = await axiosInstance.post('/auth/google', { code });
        logger.info('Token exchange successful');
        return response.data;
      } catch (error) {
        logger.error('Token exchange failed:', error);
        throw error;
      }
    },
    refreshToken: async (refreshToken: string): Promise<{ access_token: string }> => {
      const response = await axiosInstance.post('/auth/refresh-token', { refresh_token: refreshToken });
      return response.data;
    }
  },

  getGraphData: async (token: string) => {
    try {
      const response = await axiosInstance.get('/images/graph', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      logger.error('Failed to fetch graph data:', error);
      throw new Error('Failed to fetch graph data');
    }
  }
};

export default api;
