import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logger from '../utils/logger';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, handleAuthCode } = useAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const processAuth = async () => {
      const code = searchParams.get('code');
      const error = searchParams.get('error');
      
      logger.info('Auth callback received:', { 
        hasCode: !!code,
        error: error || 'none'
      });
      
      if (error) {
        logger.error('OAuth error:', error);
        setError(`Authentication failed: ${error}`);
        return;
      }
      
      if (!code) {
        logger.error('No code parameter found in URL');
        setError('No authentication code received');
        return;
      }

      try {
        await handleAuthCode(code);
        logger.info('Successfully processed auth code');
      } catch (err) {
        logger.error('Error handling auth code:', err);
        setError('Authentication failed. Please try again.');
      }
    };

    processAuth();
  }, [searchParams, handleAuthCode]);

  useEffect(() => {
    if (isAuthenticated) {
      logger.info('User authenticated, navigating to browse');
      navigate('/browse');
    }
  }, [isAuthenticated, navigate]);

  if (error) {
    return (
      <div className="auth-callback error">
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/login')}>Return to Login</button>
      </div>
    );
  }

  return (
    <div className="auth-callback">
      <p>Completing authentication...</p>
    </div>
  );
};

export default AuthCallback;