import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import api from '../services/api';
import logger from '../utils/logger';
import { User } from '../types/index';

interface AuthContextType {
  user: User | null;
  login: () => void;
  logout: () => void;
  isAuthenticated: boolean;
  isWhitelisted: boolean;
  token: string | null;
  handleAuthCode: (code: string) => Promise<void>;
  refreshToken: () => Promise<string>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const savedUser = localStorage.getItem('userInfo');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [token, setToken] = useState<string | null>(() => localStorage.getItem('accessToken'));
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(() => {
    const savedWhitelisted = localStorage.getItem('isWhitelisted');
    return savedWhitelisted === 'true';
  });

  const logout = useCallback(() => {
    logger.info('Logging out user');
    setUser(null);
    setToken(null);
    setIsWhitelisted(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('isWhitelisted');
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const storedRefreshToken = localStorage.getItem('refreshToken');
      if (!storedRefreshToken) {
        logger.error('No refresh token found');
        throw new Error('No refresh token');
      }
      
      const response = await api.auth.refreshToken(storedRefreshToken);
      const { access_token } = response;
      
      setToken(access_token);
      localStorage.setItem('accessToken', access_token);
      api.setAuthToken(access_token);
      
      return access_token;
    } catch (error) {
      logger.error('Token refresh failed:', error);
      logout();
      throw error;
    }
  }, [logout]);

  // Initialize auth state and validate token
  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem('accessToken');
      const storedUser = localStorage.getItem('userInfo');

      if (storedToken && storedUser) {
        try {
          // Set initial auth state
          api.setAuthToken(storedToken);
          setToken(storedToken);
          setUser(JSON.parse(storedUser));
          
          // Validate token and get user info
          const userInfo = await api.getUserInfo(storedToken);
          const whitelisted = userInfo.email && (
            userInfo.email === 'bryceewatson@gmail.com' || 
            userInfo.email === 'bwats@bwats.net'
          );
          setIsWhitelisted(Boolean(whitelisted));
          localStorage.setItem('isWhitelisted', String(whitelisted));
        } catch (error) {
          logger.error('Token validation failed:', error);
          // If token validation fails, try to refresh
          try {
            const newToken = await refreshToken();
            api.setAuthToken(newToken);
            setToken(newToken);
          } catch (refreshError) {
            logger.error('Token refresh failed:', refreshError);
            // Clear auth state if refresh fails
            logout();
          }
        }
      }
    };

    initializeAuth();
  }, [logout, refreshToken]);

  const handleAuthCode = useCallback(async (code: string) => {
    try {
      logger.info('Exchanging auth code for tokens');
      const response = await api.auth.exchangeCodeForTokens(code);
      const { tokens, user, isWhitelisted: whitelisted } = response;
      
      logger.info('Auth code exchange successful', { 
        hasAccessToken: !!tokens.access_token,
        hasRefreshToken: !!tokens.refresh_token,
        userEmail: user.email,
        isWhitelisted: whitelisted 
      });
      
      setToken(tokens.access_token);
      setUser(user);
      setIsWhitelisted(whitelisted);
      
      localStorage.setItem('accessToken', tokens.access_token);
      if (tokens.refresh_token) {
        localStorage.setItem('refreshToken', tokens.refresh_token);
      }
      localStorage.setItem('userInfo', JSON.stringify(user));
      localStorage.setItem('isWhitelisted', String(whitelisted));
    } catch (error) {
      logger.error('Auth code exchange failed:', error);
      throw error;
    }
  }, []);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (response) => {
      logger.info('Google login success, redirecting to callback');
      // The callback URL will handle the code
    },
    onError: (error) => {
      logger.error('Google login error:', error);
    },
    scope: 'openid email profile',
    ux_mode: 'redirect',
    redirect_uri: `${window.location.origin}/auth/callback`
  });

  const value = useMemo(() => ({
    user,
    login,
    logout,
    isAuthenticated: !!token,
    isWhitelisted,
    token,
    handleAuthCode,
    refreshToken
  }), [user, login, logout, token, isWhitelisted, handleAuthCode, refreshToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
