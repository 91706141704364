import React from 'react';
import { Modal, Button } from 'rsuite';
import type { GraphNode, ImageGraphNode } from '../types';

interface NodeDetailModalProps {
  node: GraphNode | null;
  open: boolean;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'full';
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
}

const NodeDetailModal: React.FC<NodeDetailModalProps> = ({ 
  node, 
  open, 
  onClose, 
  size = 'md',
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy
}) => {
  if (!node) return null;

  const renderContent = () => {
    switch (node.type) {
      case 'image':
        const imageNode = node as ImageGraphNode;
        return (
          <div className="image-detail-container">
            <img 
              src={imageNode.fullUrl || imageNode.mediumUrl} 
              alt={imageNode.title || 'Image'} 
              style={{ maxWidth: '100%', maxHeight: '70vh' }}
            />
            <div className="image-metadata">
              <h3>{imageNode.title || 'Untitled'}</h3>
              {imageNode.description && <p>{imageNode.description}</p>}
              <div className="tags-container">
                {imageNode.styles?.map((style: string) => (
                  <span key={style} className="tag">Style: {style}</span>
                ))}
                {imageNode.moods?.map((mood: string) => (
                  <span key={mood} className="tag">Mood: {mood}</span>
                ))}
                {imageNode.techniques?.map((technique: string) => (
                  <span key={technique} className="tag">Technique: {technique}</span>
                ))}
              </div>
            </div>
          </div>
        );
      
      // Add other node type renders as needed
      default:
        return <div>Details not available for this node type</div>;
    }
  };

  return (
    <Modal 
      open={open} 
      onClose={onClose}
      size={size}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      backdrop="static"
      keyboard={true}
      overflow={true}
    >
      <Modal.Header>
        <Modal.Title id={ariaLabelledBy}>{node.label || 'Node Details'}</Modal.Title>
      </Modal.Header>
      <Modal.Body id={ariaDescribedBy}>
        {renderContent()}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NodeDetailModal;
