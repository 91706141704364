import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Button, Message, toaster } from 'rsuite';
import logger from '../utils/logger';

const Login: React.FC = () => {
  const { login, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  if (isAuthenticated) {
    return <Navigate to="/browse" />;
  }

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      logger.info('Initiating Google OAuth login');
      await login();
    } catch (error) {
      logger.error('Login error:', error);
      toaster.push(
        <Message type="error">
          Failed to initiate login. Please try again.
        </Message>
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h1>Welcome to starz</h1>
      <p>Please sign in with your Google account to continue.</p>
      <Button 
        onClick={handleLogin}
        appearance="primary" 
        loading={isLoading}
        disabled={isLoading}
        data-testid="login-button"
      >
        {isLoading ? 'Redirecting...' : 'Sign in with Google'}
      </Button>
    </div>
  );
};

export default Login;
