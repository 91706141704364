// src/constants.ts
/**
 * Graph Node Size Configuration
 * 
 * Controls the visual appearance and physics behavior of different node types
 * in the force-directed graph visualization.
 */
export const GRAPH_NODE_SIZES = {
  IMAGE: {
    // Base size for image node rendering (affects visual size)
    BASE: 240,
    // Size used for collision detection (prevents overlap)
    COLLISION: 240,  // About 2/3 of BASE
    // Link distance multiplier for image-to-image connections
    LINK_DISTANCE: 300,
    // Charge strength for image nodes (affects repulsion)
    CHARGE: -500
  },
  USER: {
    // Visual radius of user nodes
    RADIUS: 30,
    // Collision radius for user nodes
    COLLISION: 30,
    // Link distance for user-to-any connections
    LINK_DISTANCE: 500,
    // Charge strength for user nodes
    CHARGE: -150
  },
  ATTRIBUTE: {
    // Visual radius of attribute nodes
    RADIUS: 15,
    // Collision radius for attribute nodes
    COLLISION: 15,
    // Link distance for attribute connections
    LINK_DISTANCE: 150,
    // Charge strength for attribute nodes
    CHARGE: -50
  }
} as const;

