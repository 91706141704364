import React, { useState, useCallback } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { CustomProvider, Drawer } from 'rsuite';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Browse from './pages/Browse';
import ImageView from './components/ImageView';
import NavigationBar from './components/NavigationBar';
import UploadDrawer from './components/UploadDrawer';
import Login from './components/Login';
import { GraphSettingsProvider } from './contexts/GraphSettingsContext';
import AuthCallback from './components/AuthCallback';
import ErrorBoundary from './components/ErrorBoundary';

const ImageViewWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return id ? <ImageView id={id} /> : null;
};

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated, isWhitelisted } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (!isWhitelisted) {
    return <NotWhitelisted />;
  }
  return element;
};

const NotWhitelisted: React.FC = () => (
  <div data-testid="unauthorized-message" style={{ textAlign: 'center', marginTop: '50px' }}>
    <h2>Access Restricted</h2>
    <p>We're sorry, but access to this site is currently limited to invited users only.</p>
    <p>If you believe you should have access, please contact our support team.</p>
  </div>
);

const AppContent: React.FC = () => {
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [uploadOccurred, setUploadOccurred] = useState(false);

  const toggleUploadDrawer = () => {
    setIsUploadDrawerOpen(!isUploadDrawerOpen);
  };

  const handleUploadSuccess = useCallback(() => {
    setIsUploadDrawerOpen(false);
    setUploadOccurred(true);
  }, []);

  return (
    <CustomProvider theme="dark">
      <div data-testid="app-ready" className="dark-theme">
        <NavigationBar onUploadClick={toggleUploadDrawer} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/browse" replace />} />
          <Route path="/browse" element={<PrivateRoute element={<Browse uploadOccurred={uploadOccurred} setUploadOccurred={setUploadOccurred} />} />} />
          <Route path="/image/:id" element={<PrivateRoute element={<ImageViewWrapper />} />} />
          <Route path="/auth/callback" element={
            <ErrorBoundary fallback={<div>Authentication error. Please try again.</div>}>
              <AuthCallback />
            </ErrorBoundary>
          } />
          <Route path="*" element={<Navigate to="/browse" replace />} />
        </Routes>
        <Drawer open={isUploadDrawerOpen} onClose={() => setIsUploadDrawerOpen(false)} size="sm">
          <UploadDrawer 
            open={isUploadDrawerOpen}
            onClose={() => setIsUploadDrawerOpen(false)}
            onUploadSuccess={handleUploadSuccess}
          />
        </Drawer>
      </div>
    </CustomProvider>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <GraphSettingsProvider>
        <AppContent />
      </GraphSettingsProvider>
    </AuthProvider>
  );
};

export default App;
